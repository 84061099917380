<template>
  <div id="sales">
    <h2>运营数据</h2>
    <div class="module">
      <div class="module-item">
        <img src="../../assets/images/bigScreen/sales1.png" alt="" />
        <div>
          <p>当前运营额</p>
          <div>
            <p>
              <span>{{ perating.amount }}</span>
              <i>元</i>
            </p>
            <img
              :class="{ opacityAnimation: animation }"
              :src="
                require(`../../assets/images/bigScreen/${perating.direction}.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="module-item">
        <img src="../../assets/images/bigScreen/sales2.png" alt="" />
        <div>
          <p>总运营额</p>
          <div>
            <p>
              <span>{{ perating.totalAmount }}</span>
              <i>元</i>
            </p>
            <img
              :src="
                require(`../../assets/images/bigScreen/${perating.totalAmount_direction}.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="module-item">
        <img src="../../assets/images/bigScreen/sales3.png" alt="" />
        <div>
          <p>今日访问量</p>
          <div>
            <p>
              <span>{{ visits.amount }}</span>
            </p>
            <img
              :src="
                require(`../../assets/images/bigScreen/${visits.totalAmount_direction}.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="module-item">
        <img src="../../assets/images/bigScreen/sales4.png" alt="" />
        <div>
          <p>总访问量</p>
          <div>
            <p>
              <span>{{ visits.totalAmount }}</span>
            </p>
            <img
              :src="
                require(`../../assets/images/bigScreen/${visits.totalAmount_direction}.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      perating: {
        amount: 0,
        direction: "top",
        totalAmount: 0,
        totalAmount_direction: "top",
      },
      visits: {
        amount: 0,
        direction: "top",
        totalAmount: 0,
        totalAmount_direction: "top",
      },
      animation: null,
    };
  },
  created() {
    this.getPeratingAmount();
    this.getVisits();
  },
  methods: {
    async getPeratingAmount() {
      try {
        let res = await this.$http.get("/home/sales/contrast");
        if (res.code === 0) {
          this.perating = {
            amount: res.data.currentSales,
            direction:
              this.perating.amount <= res.data.currentSales ? "top" : "bottom",
            totalAmount: res.data.totalSales,
            totalAmount_direction:
              this.perating.totalAmount <= res.data.totalSales
                ? "top"
                : "bottom",
          };
          if (this.perating.amount <= res.data.currentSales) {
            this.animation = setTimeout(() => {
              clearTimeout(this.animation);
              this.animation = null;
            }, 3000);
          }
          setTimeout(() => {
            this.getPeratingAmount();
          }, 1000 * 30);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getVisits() {
      try {
        let res = await this.$http.get("/home/visit/day");
        if (res.code === 0) {
          if (!res.data) {
            return;
          }
          this.visits = {
            amount: res.data.today,
            direction: this.visits.amount <= res.data.today ? "top" : "bottom",
            totalAmount: res.data.total,
            totalAmount_direction:
              this.visits.totalAmount <= res.data.total ? "top" : "bottom",
          };
          setTimeout(() => {
            this.getVisits();
          }, 1000 * 30);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#sales {
  width: 100%;
  height: 20%;
  margin-bottom: 2.8rem;
  .module {
    height: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .module-item {
      width: 47%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > img {
        width: 3.38rem;
        height: 3.44rem;
      }
      & > div {
        width: calc(100% - 3.69rem);
        & > p {
          color: white;
          font-size: 0.88rem;
          margin-bottom: 0.19rem;
        }
        & > div {
          display: flex;
          justify-content: space-between;
          p {
            white-space: nowrap;
          }
          span {
            font-size: 1.32rem;
            color: #01e3fc;
          }
          i {
            font-style: initial;
            font-size: 0.88rem;
            color: white;
            margin-left: 0.25rem;
          }
          img {
            height: 80%;
            opacity: 0;
          }
          .opacityAnimation {
            animation: imgOpacity 1s ease infinite;
          }
        }
      }

      @keyframes imgOpacity {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }
  }
}
</style>
